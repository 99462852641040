<template>
	<div class="profile">
		<div v-if="profile.imageUrl">
			<img
				class="chip chip-large"
				:src="profile.imageUrl">
			<h2>{{profile.name}}</h2>
			<span>{{profile.email}}</span>
			<br><br>
			<div class="label-input blue" v-for="(f, i) of profile" :key="i">
				<input v-if="fieldType(i) === 'NUM'" type="number"
					v-model.trim="editedFields[i]"
					placeholder="_">
				<input v-else type="text"
					v-model.trim="editedFields[i]"
					placeholder="_">
				<label :data-content="i">{{i}}</label>
			</div>
			<br>
			<button @click="saveProfile">Save</button>
		</div>
		<div v-else>
			<span>Please sign in</span>
		</div>
	</div>
</template>

<script>
import {inject, reactive, computed} from 'vue'
import {useStore} from 'vuex'

export default {
	name: 'profile',
	setup() {
		const api = inject('api')
		const editableFields = reactive({})
		const store = useStore()
		const profile = computed(() => store.state.profile)

		const updateProfile = api.user.updateProfile({...editableFields})

		return {
			updateProfile,
			editableFields,
			profile,
		}
	},
	watch: {
		'profile': {
			deep: true,
			handler(profile) {
				if (!profile.name) {
					this.$router.push({name: 'Dashboard'})
				}
			},
		},
	},
	methods: {
		fieldType(field) {
			switch (field.toUpperCase()) {
				case 'ZIP': return 'STRING'
				default: return 'STRING'
			}
		},
	},
}
</script>

<style lang="stylus" scoped>
.profile
	padding: 16px
	margin-top: 40px
</style>
